import React from 'react';
import BenefitGrid from './BenefitGrid';
import './Home.css';
// import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="home">
      <header className="hero">
        <h1>Bienvenidos a Monkey Beneficios</h1>
        <p>Explora nuestros beneficios exclusivos para ti.</p>
      </header>
      <section className="benefits">
        <h2>Beneficios Destacados</h2>
        <BenefitGrid />
        {/* <button>
          <Link to="/all-benefits">Ver Todos los Beneficios</Link>
        </button> */}
      </section>
      {/* <section className="hero hero-1">
        <h2>Sección Adicional 1</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Maecenas sit amet nulla dapibus, commodo quam sit amet, tincidunt mi. Suspendisse potenti.
        </p>
      </section>
      <section className="hero hero-2">
        <h2>Sección Adicional 2</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Maecenas sit amet nulla dapibus, commodo quam sit amet, tincidunt mi. Suspendisse potenti.
        </p>
      </section> */}
    </div>
  );
};

export default Home;
