import React, { useState } from 'react';
import BenefitCard from './BenefitCard';
import BenefitDetailsModal from './BenefitDetailsModal';
import './BenefitGrid.css';
import useFetchBenefits from '../hooks/useFetchBenefits';

const BenefitGrid = ({ limit }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { benefits, loading } = useFetchBenefits(apiUrl);
  const [selectedBenefit, setSelectedBenefit] = useState(null);

  if (loading) {
    return <div>Loading...</div>;
  }

  const displayedBenefits = limit ? benefits.slice(0, limit) : benefits;

  return (
    <div className="benefit-grid-container">
      <div className="benefit-grid">
        {displayedBenefits.map((benefit) => (
          <BenefitCard 
            key={benefit.id} 
            benefit={benefit} 
            onClick={() => setSelectedBenefit(benefit)} 
          />
        ))}
        {selectedBenefit && (
          <BenefitDetailsModal
            benefit={selectedBenefit}
            onClose={() => setSelectedBenefit(null)}
          />
        )}
      </div>
    </div>
  );
};

export default BenefitGrid;
