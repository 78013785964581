import React, { useEffect } from 'react';
import './BenefitDetailsModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const BenefitDetailsModal = ({ benefit, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <div className="modal-body">
          <img src={benefit.image_cover} alt={benefit.title} className="modal-image" />
          <div className="modal-details">
            <h2>{benefit.title}</h2>
            <p>{benefit.description}</p>
            <p><strong>Condiciones:</strong> {benefit.conditions}</p>
            {benefit.start_date && (
                <p><strong>Inicio:</strong> {benefit.start_date}</p>
            )}
            {benefit.end_date && (
                <p><strong>Fin:</strong> {benefit.end_date}</p>
            )}
            {benefit.legal_terms && (
                <p><strong>Términos legales:</strong> {benefit.legal_terms}</p>
            )}
            {benefit.address && (
                <p><strong>Dirección:</strong> {benefit.address}</p>
            )}
            {benefit.email && (
                <p><strong>Email:</strong> {benefit.email}</p>
            )}
            {benefit.phone && (
                <p><strong>Teléfono:</strong> {benefit.phone}</p>
            )}
            {benefit.list_price && (
                <div className="price-details">
                  <p><strong>Precio:</strong> <span className="list-price">${benefit.list_price}</span> ${benefit.discount_price} ({benefit.discount_pct}%)</p>
                </div>
            )}
            <p><strong>Código promocional:</strong> {benefit.promo_code}</p>
            <div className="social-icons">
              {benefit.whatsapp && (
                <a href={`https://wa.me/${benefit.whatsapp}`} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faWhatsapp} size="2x" className="icon whatsapp" />
                </a>
              )}
              {benefit.web && (
                <a href={benefit.web} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGlobe} size="2x" className="icon web" />
                </a>
              )}
              {benefit.twitter && (
                <a href={`https://twitter.com/${benefit.twitter}`} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} size="2x" className="icon twitter" />
                </a>
              )}
              {benefit.facebook && (
                <a href={benefit.facebook} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} size="2x" className="icon facebook" />
                </a>
              )}
              {benefit.instagram && (
                <a href={`${benefit.instagram}`} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} size="2x" className="icon instagram" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitDetailsModal;
