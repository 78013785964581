import React from 'react';
import './Navbar.css'; // Archivo CSS para estilos

const Navbar = () => {
  return (
    <header className="navbar">
      <div className="navbar-container">
        {/* Logo */}
        <div className="navbar-logo">
          <a href="https://www.monkeyfitpass.com/">
            <img src="https://static.monkeyfitpass.com/images/logos/new-logo.png" alt="MonkeyFit Logo" className="logo" />
          </a>
        </div>

        {/* Botón Crear Cuenta (Visible en Desktop y Mobile) */}
        <div className="navbar-cta">
          <a href="https://www.monkeyfitpass.com/" className="cta-button">Ir a la plataforma</a>
        </div>

      </div>
    </header>
  );
};

export default Navbar;
