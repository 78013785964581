import React from 'react';
import './BenefitCard.css';

const BenefitCard = ({ benefit, onClick }) => {
  return (
    <div className="benefit-card">
      <img src={benefit.image_cover} alt={benefit.title} className="benefit-image" />
      {/* <div className="exclusive-label">Sólo para pass</div> */}
      <h3>{benefit.title}</h3>
      <p className="benefit-description">{benefit.description}</p>
      <button onClick={onClick}>Ver Beneficio</button>
    </div>
  );
};

export default BenefitCard;
