// src/components/Footer.jsx
import React from 'react';
import './Footer.css'; // Archivo CSS para estilos

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <div className="footer-logo-text">
            <img src="https://static.monkeyfitpass.com/images/logos/logo-white.png" alt="MonkeyFit Logo" className="footer-logo" />
            <h2>Entrena, medita y come saludable</h2>
          </div>
          <p className="footer-contact">¡Mantengamos el contacto!</p>
          <div className="footer-social">
            <a href="#instagram"><i className="fab fa-instagram"></i></a>
            <a href="#youtube"><i className="fab fa-youtube"></i></a>
            <a href="#facebook"><i className="fab fa-facebook"></i></a>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-links">
            <a href="#company">Compañía</a>
            <a href="#legal">Legal</a>
          </div>
          <div className="footer-app-buttons">
            <p>Encuéntranos en:</p>
            <a href="#googleplay">
              <img src="https://static.monkeyfitpass.com/images/empleados/google.png" alt="Disponible en Google Play" />
            </a>
            <a href="#appstore">
              <img src="https://static.monkeyfitpass.com/images/empleados/apple.png" alt="Disponible en App Store" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
