import { useState, useEffect } from 'react';

const fallbackBenefits = [
  {
    id: 1,
    partner: 'Partner A',
    title: 'Beneficio A',
    description: 'Descripción del beneficio A',
    conditions: 'Condiciones del beneficio A',
    start_date: '2024-01-01',
    end_date: '2024-12-31',
    legal_terms: 'Términos legales del beneficio A',
    image_cover: 'https://via.placeholder.com/200',
    video: 'https://via.placeholder.com/200',
    address: 'Dirección A',
    email: 'emailA@example.com',
    phone: '123-456-7890',
    whatsapp: '123-456-7890',
    web: 'https://exampleA.com',
    twitter: '@exampleA',
    facebook: 'https://facebook.com/exampleA',
    instagram: 'https://instagram.com/exampleA',
    is_active: true,
    created_at: '2023-01-01',
    updated_at: '2023-01-10',
    created_by_id: 1,
    discount_pct: 10,
    discount_price: 90,
    list_price: 100,
    promo_code: 'PROMOA',
    slug: 'beneficio-a'
  },
  {
    id: 2,
    partner: 'Partner B',
    title: 'Beneficio B',
    description: 'Descripción del beneficio B',
    conditions: 'Condiciones del beneficio B',
    start_date: '2024-01-01',
    end_date: '2024-12-31',
    legal_terms: 'Términos legales del beneficio B',
    image_cover: 'https://via.placeholder.com/200',
    video: 'https://via.placeholder.com/200',
    address: 'Dirección B',
    email: 'emailB@example.com',
    phone: '987-654-3210',
    whatsapp: '987-654-3210',
    web: 'https://exampleB.com',
    twitter: '@exampleB',
    facebook: 'https://facebook.com/exampleB',
    instagram: 'https://instagram.com/exampleB',
    is_active: true,
    created_at: '2023-01-01',
    updated_at: '2023-01-10',
    created_by_id: 2,
    discount_pct: 15,
    discount_price: 85,
    list_price: 100,
    promo_code: 'PROMOB',
    slug: 'beneficio-b'
  }
  // Añadir más beneficios según sea necesario
];

const useFetchBenefits = (url) => {
  const [benefits, setBenefits] = useState(fallbackBenefits);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBenefits = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error fetching data');
        }
        const data = await response.json();
        setBenefits(data.results);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBenefits();
  }, [url]);

  return { benefits, loading, error };
};

export default useFetchBenefits;
